import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Media from 'react-media'
import find from 'lodash/find'
import isUndefined from 'lodash/isUndefined'

import { loadArticlesIfNeeded, updatePage } from 'actions/articles'
import { logRequest } from 'actions/requests'
import { Article, Categories, CategoriesMenu, CoachingLink, List } from 'components/'
import { ARTICLES_DEFAULT_CATEGORY_CODE } from 'constants/app'
import history from 'services/history'

// feature: financial_library
export class Articles extends Component {
  articlesRef = React.createRef()

  componentDidMount() {
    document.title = 'Articles - MSA Members'

    const {
      feature,
      loadArticlesIfNeeded,
      logRequest,
      match: {
        params: { category, id }
      }
    } = this.props

    if (!category) {
      // redirect to the default category, if one was not provided
      history.replace(`/articles/${ARTICLES_DEFAULT_CATEGORY_CODE}`)
    } else {
      logRequest({
        feature,
        category: category || undefined,
        item: id || undefined,
        action: 'view'
      })
      loadArticlesIfNeeded(feature, category, id)
      updatePage(1)
    }
  }

  handlePageChange = page => {
    this.props.updatePage(page)
    this.articlesRef.current.scrollIntoView()
  }

  render() {
    const {
      article,
      articles,
      brand,
      categories,
      hasCoaching,
      match: {
        params: { category, id }
      },
      page
    } = this.props

    return (
      <Media query="(min-width: 830px)">
        {matches =>
          matches ? (
            <div className="articles" ref={this.articlesRef}>
              <div className="left-side-nav">
                <Categories brand={brand} entity="articles" items={categories} />
              </div>
              {!id && articles.activeCategory === category && (
                <List
                  brand={brand}
                  ref={this.listRef}
                  category={category}
                  items={articles.list}
                  onPageChange={this.handlePageChange}
                  page={page}
                />
              )}
              {id && article.item.code === id && <Article brand={brand} item={article.item} />}
              <div className="right-side-nav">{hasCoaching && <CoachingLink brand={brand} />}</div>
            </div>
          ) : (
            <div className="articles compact" ref={this.articlesRef}>
              <CategoriesMenu
                brand={brand}
                entity="articles"
                items={categories}
                title="Recent Posts"
              />
              {!id && articles.activeCategory === category && (
                <List
                  brand={brand}
                  category={category}
                  compact={true}
                  items={articles.list}
                  onPageChange={this.handlePageChange}
                  page={page}
                />
              )}
              {id && article.item.code === id && <Article brand={brand} item={article.item} />}
              {hasCoaching && <CoachingLink brand={brand} />}
            </div>
          )
        }
      </Media>
    )
  }
}

Articles.propTypes = {
  article: PropTypes.object.isRequired,
  articles: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  feature: PropTypes.string.isRequired,
  hasCoaching: PropTypes.bool.isRequired,
  loadArticlesIfNeeded: PropTypes.func.isRequired,
  logRequest: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
}

const mapStateToProps = state => {
  const hasCoaching = find(state.features.list, { code: 'financial_resources' })

  return {
    article: state.articles.current,
    articles: state.articles,
    brand: state.brand,
    categories: state.articles.categories,
    hasCoaching: !isUndefined(hasCoaching),
    page: state.articles.current.page
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadArticlesIfNeeded,
      logRequest,
      updatePage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Articles)
