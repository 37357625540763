import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Question extends Component {
  render() {
    const { brand, question, onSelectScore, selectedValue } = this.props
    const scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const selectedStyle = {
      backgroundColor: brand.colors.buttonBackgroundColor,
      color: brand.colors.buttonTextColor
    }
    const idForAriaDescribed = scoreNum => {
      if (scoreNum === 1) {
        return 'level1'
      } else if (scoreNum === 10) {
        return 'level10'
      }
    }
    return (
      <div className="question">
        <h1 id="questionHeader" tabIndex="-1">
          {question.question}
        </h1>
        <div className="scores">
          {scores.map(i => (
            <button
              aria-describedby={idForAriaDescribed(i)}
              key={i}
              className={i === selectedValue ? 'selected' : null}
              aria-pressed={i === selectedValue ? true : false}
              style={i === selectedValue ? selectedStyle : null}
              onClick={se => onSelectScore(i)}
            >
              {i}
            </button>
          ))}
        </div>
        <div className="score-labels" style={{ color: brand.colors.headerTextColor }}>
          <span id="level1" aria-hidden="true">
            {question.a1}
          </span>
          <span id="level10" aria-hidden="true">
            {question.a10}
          </span>
        </div>
      </div>
    )
  }
}

Question.propTypes = {
  brand: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  onSelectScore: PropTypes.func.isRequired,
  selectedValue: PropTypes.number.isRequired
}

export default Question
