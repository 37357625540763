import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { logRequest } from 'actions/requests'
import { loadQuestionsIfNeeded } from 'actions/questions'
import { addScore, resetScores } from 'actions/scores'
import { Questions, Results } from 'components/'

// feature: financial_checkup
export class Checkup extends Component {
  state = {
    stage: 'start'
  }

  checkupHeader = React.createRef()

  componentDidMount() {
    document.title = 'Checkup - MSA Members'
    const el = this.checkupHeader.current
    el.focus()
    el.style.outline = 'none'

    const { feature, loadQuestionsIfNeeded, logRequest, resetScores } = this.props
    logRequest({
      feature,
      action: 'view'
    })
    loadQuestionsIfNeeded()
    resetScores()
  }

  componentDidUpdate(prevProps) {
    const { feature, logRequest, scores } = this.props
    if (scores.results !== prevProps.scores.results && scores.results !== null) {
      logRequest({
        feature,
        action: 'submit'
      })
      this.setState({ stage: 'results' })
    }
  }

  handleAddScore = (question, answer, save) => {
    const { addScore } = this.props
    addScore(question, answer, save)
  }

  handleStartCheckup = () => {
    this.setState({
      stage: 'questions'
    })
  }

  render() {
    const { brand, feature, features, scores, questions } = this.props
    const {
      colors: { buttonBackgroundColor, buttonTextColor, headerTextColor }
    } = brand
    const { stage } = this.state
    const featured = features.list.length ? features.list.find(f => f.code === feature) : null
    return (
      <div className="checkup">
        {stage === 'start' && (
          <div className="overview">
            <h1 style={{ color: headerTextColor }} ref={this.checkupHeader} tabIndex="-1">
              Welcome to your Financial Checkup!
            </h1>
            <p>{featured && featured.summary}</p>
            <p>
              Once you click BEGIN, you will be asked {questions.list.length} questions pertaining
              to your current financial situation.
            </p>
            <p>This information is not shared with your employer and is completely confidential.</p>
            <button
              className="button"
              onClick={this.handleStartCheckup}
              style={{ backgroundColor: buttonBackgroundColor, color: buttonTextColor }}
            >
              Begin
            </button>
          </div>
        )}
        {stage === 'questions' && (
          <Questions
            brand={brand}
            scores={scores}
            questions={questions}
            onAddScore={this.handleAddScore}
          />
        )}
        {stage === 'results' && <Results brand={brand} results={scores.results} />}
      </div>
    )
  }
}

Checkup.propTypes = {
  addScore: PropTypes.func.isRequired,
  brand: PropTypes.object.isRequired,
  feature: PropTypes.string.isRequired,
  features: PropTypes.object.isRequired,
  loadQuestionsIfNeeded: PropTypes.func.isRequired,
  logRequest: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  resetScores: PropTypes.func.isRequired,
  scores: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    brand: state.brand,
    features: state.features,
    questions: state.questions,
    scores: state.scores
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addScore,
      // loadFeaturesIfNeeded,
      loadQuestionsIfNeeded,
      logRequest,
      resetScores
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Checkup)
