import {
  SCORES_ADD,
  SCORES_CURRENT_ADD,
  SCORES_RESET,
  SCORES_SAVE_FAILURE,
  SCORES_SAVE_REQUEST,
  SCORES_SAVE_SUCCESS
} from 'constants/actionTypes'
import { msaApi } from 'services/apis'

export const addScore = (question, answer, save = false) => {
  return dispatch => {
    dispatch({
      type: SCORES_ADD,
      score: {
        ...question,
        score: answer
      }
    })

    if (save) {
      dispatch(saveScores())
    } else {
      dispatch(incrementCurrent())
    }
  }
}

export const resetScores = () => {
  return {
    type: SCORES_RESET
  }
}

export const saveScores = () => {
  return async (dispatch, getState) => {
    const { scores } = getState()

    dispatch({
      type: SCORES_SAVE_REQUEST
    })

    // send email
    const payload = { questions: scores.list }
    const [error, results] = await msaApi.post('pfw_scores', payload)

    if (!error) {
      dispatch({
        type: SCORES_SAVE_SUCCESS,
        results
      })
    } else {
      dispatch({
        type: SCORES_SAVE_FAILURE,
        message: error
      })
    }
  }
}

const incrementCurrent = () => {
  return {
    type: SCORES_CURRENT_ADD
  }
}
