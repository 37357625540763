import {
  CONTACT_SEND_FAILURE,
  CONTACT_SEND_REQUEST,
  CONTACT_SEND_RESET,
  CONTACT_SEND_SUCCESS
} from 'constants/actionTypes'

import { CONTACT_FAILURE_MESSAGE, CONTACT_SUCCESS_MESSAGE } from 'constants/messages'
import { msaApi } from 'services/apis'

export const resetContactStatus = () => {
  return {
    type: CONTACT_SEND_RESET
  }
}

export const sendContactMessage = ({ firstName, lastName, email, message, phone }) => {
  return async (dispatch, getState) => {
    // TODO get recipient email from org config
    // const orgEmail = getState().brand.email
    const recipient = 'emartin24@gmail.com'
    const subject = 'MSA Contact Form'

    dispatch({
      type: CONTACT_SEND_REQUEST
    })

    // send email
    const payload = { recipient, subject, name: `${firstName} ${lastName}`, email, message, phone }
    const [error] = await msaApi.post('messages', payload)

    if (!error) {
      dispatch({
        type: CONTACT_SEND_SUCCESS,
        message: CONTACT_SUCCESS_MESSAGE
      })
    } else {
      dispatch({
        type: CONTACT_SEND_FAILURE,
        message: CONTACT_FAILURE_MESSAGE
      })
    }
  }
}
