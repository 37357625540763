import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const TableOfContentsLinks = ({ brand, category, items }) =>
  Object.keys(items).map(topic => (
    <React.Fragment key={topic}>
      <div
        className="topic"
        aria-label={`Video catagory ${topic}`}
        style={{
          backgroundColor: brand.colors.buttonBackgroundColor,
          color: brand.colors.buttonTextColor
        }}
      >
        <span aria-hidden="true">{topic}</span>
        <i className="material-icons" style={{ color: brand.colors.buttonTextColor }} aria-hidden="true">
          keyboard_arrow_down
        </i>
      </div>
      <div className="topic-videos">
        {items[topic].map(video => (
          <NavLink
            key={video.id}
            to={`/videos/${category}/${video.code}`}
            activeStyle={{ color: brand.colors.menuTextSelectedColor }}
            aria-label={`Visit our ${video.title} page`}
          >
            <span>{video.title}</span>
          </NavLink>
        ))}
      </div>
    </React.Fragment>
  ))

TableOfContentsLinks.propTypes = {
  brand: PropTypes.object.isRequired,
  category: PropTypes.string,
  items: PropTypes.object.isRequired
}

export default TableOfContentsLinks
