export const decodeHtmlEntity = str => {
  return str.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec)
  })
}

export const slugify = (value, slug = '-') => {
  return typeof value === 'string'
    ? value
        .replace(/&/g, 'and')
        .replace(/\s+/g, slug)
        .toLowerCase()
    : value
}

export const unslugify = (value, slug = '-') => {
  const re = new RegExp(slug, 'g')
  return value && typeof value === 'string'
    ? value
        .replace(re, ' ')
        .replace(/\band\b/g, '&')
        .split(' ')
        .map(w => w.charAt(0).toUpperCase() + w.substring(1, w.length))
        .join(' ') // reassemble
    : ''
}
