import { combineReducers } from 'redux'

import admin from './admin'
import app from './app'
import articles from './articles'
import brand from './brand'
import contact from './contact'
import features from './features'
import questions from './questions'
import scores from './scores'
import videos from './videos'

const rootReducer = combineReducers({
  admin,
  app,
  articles,
  brand,
  contact,
  features,
  questions,
  scores,
  videos
})

export default rootReducer
