import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TableOfContentsLinks } from 'components/'

class TableOfContentsMenu extends Component {
  state = {
    isTableOfContentsOpen: false
  }

  handleTableOfContentsToggle = () => {
    this.setState(currentState => ({
      isTableOfContentsOpen: !currentState.isTableOfContentsOpen
    }))
  }

  render() {
    const { brand, category, items } = this.props
    const { isTableOfContentsOpen } = this.state

    return (
      <div className="table-of-contents">
        <div
          className="topic-heading"
          onClick={this.handleTableOfContentsToggle}
          onKeyPress={this.handleTableOfContentsToggle}
          role="menuitem"
          tabIndex="0"
        >
          <span>Table of Contents</span>
          <i className="material-icons" style={{ color: brand.colors.buttonTextColor }}>
            {isTableOfContentsOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_left'}
          </i>
        </div>
        {isTableOfContentsOpen && (
          <div>
            <TableOfContentsLinks brand={brand} category={category} items={items} />
          </div>
        )}
      </div>
    )
  }
}

TableOfContentsMenu.propTypes = {
  brand: PropTypes.object.isRequired,
  category: PropTypes.string,
  items: PropTypes.object.isRequired
}

export default TableOfContentsMenu
