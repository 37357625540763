import {
  ARTICLE_CATEGORIES_UPDATE,
  ARTICLE_CATEGORY_UPDATE,
  ARTICLE_LOAD_FAILURE,
  ARTICLE_LOAD_SUCCESS,
  ARTICLES_LIST_FAILURE,
  ARTICLES_LIST_REQUEST,
  ARTICLES_LIST_SUCCESS,
  ARTICLES_PAGE_UPDATE
} from 'constants/actionTypes'
import { ROOT_PHONE_NUMBER } from 'constants/app'
import { msaApi } from 'services/apis'
import { decodeHtmlEntity, slugify, unslugify } from 'services/format'

export const loadArticle = (code, category) => {
  return async (dispatch, getState) => {
    const articles = getState().articles
    const brand = getState().brand
    const current = articles.current
    const article = articles.list.find(v => v.code === code)

    if (article) {
      if (brand.phone) {
        const phoneRegEx = new RegExp(`${ROOT_PHONE_NUMBER}`)
        article.description = article.description
          ? article.description.replace(phoneRegEx, brand.phone)
          : ''
      }

      if (current.category !== category) {
        dispatch({
          type: ARTICLE_CATEGORY_UPDATE,
          item: category
        })
      }

      dispatch({
        type: ARTICLE_LOAD_SUCCESS,
        item: article
      })
    } else {
      dispatch({
        type: ARTICLE_LOAD_FAILURE,
        message: `Unable to load the requested article (CODE: ${code})`
      })
    }
  }
}

export const loadArticlesIfNeeded = (featureCode, category, articleCode) => {
  return async (dispatch, getState) => {
    let articles = getState().articles
    const categories = articles.categories
    const current = articles.current
    const features = getState().features
    const categoryName = unslugify(category)
    const feature = features.list.find(f => f.code === featureCode)

    if (feature) {
      if (!categories.length) {
        dispatch(loadCategories(feature.id))
      }

      if (!articles.list.length || current.category !== category) {
        // load articles
        dispatch(loadArticles(feature.id, categoryName)).then(() => {
          articles = getState().articles
          if (articleCode) {
            dispatch(loadArticle(articleCode, category))
          } else {
            dispatch(updatePage(1))
          }
        })
      } else {
        if (articleCode) {
          dispatch(loadArticle(articleCode, category))
        }
      }
    } else {
      console.error(`A feature was not found using code ${featureCode}`)
    }
  }
}

export const updatePage = page => {
  return {
    type: ARTICLES_PAGE_UPDATE,
    item: page
  }
}

/* Private functions */

const loadArticles = (parentId, category, limit = 100) => {
  return async dispatch => {
    dispatch({
      type: ARTICLES_LIST_REQUEST
    })

    const [error, articles] = await msaApi.get(
      `features?activeOnly=true&parent_id=${parentId}&limit=${limit}${
        category && category !== 'All' ? `&category=${encodeURIComponent(category)}` : ''
      }`
    )

    if (!error) {
      return dispatch({
        type: ARTICLES_LIST_SUCCESS,
        list: articles.map(article => {
          return {
            ...article,
            summary: decodeHtmlEntity(parse(article.summary))
          }
        }),
        category: slugify(category)
      })
    } else {
      return dispatch({
        type: ARTICLES_LIST_FAILURE,
        message: error
      })
    }
  }
}

const loadCategories = (parentId, limit = 100) => {
  return async dispatch => {
    const [error, categories] = await msaApi.get(`categories?parent_id=${parentId}&limit=${limit}`)

    if (!error) {
      return dispatch({
        type: ARTICLE_CATEGORIES_UPDATE,
        list: categories.map(cat => cat.category).sort((a, b) => a.localeCompare(b))
      })
    } else {
      console.error(`An error occurred loading the Article Categories (${error})`)
    }
  }
}

const parse = str => {
  return str
    .replace(/\[&#8230;\]/, '&#8230;') // remove [] around ellipses
    .replace(/([a-z])([.!?])([0-9]+)/gi, '$1$2 $3.')
}
