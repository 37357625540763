import uniq from 'lodash/uniq'

import {
  VIDEO_CATEGORY_UPDATE,
  VIDEO_LOAD_FAILURE,
  VIDEO_LOAD_SUCCESS,
  VIDEOS_LIST_FAILURE,
  VIDEOS_LIST_REQUEST,
  VIDEOS_LIST_SUCCESS
} from 'constants/actionTypes'
import { FAILURE, SUCCESS } from 'constants/app'

const initialState = {
  categories: [],
  current: {
    category: null,
    item: {},
    tableOfContents: {},
    message: null,
    status: null
  },
  isFetching: false,
  list: [],
  message: null,
  status: null
}

export default function videos(state = initialState, action) {
  switch (action.type) {
    case VIDEO_CATEGORY_UPDATE:
      const { category, tableOfContents } = action.payload

      return {
        ...state,
        current: {
          ...state.current,
          category,
          tableOfContents
        }
      }
    case VIDEO_LOAD_FAILURE:
      return {
        ...state,
        current: {
          ...state.current,
          message: action.message,
          status: FAILURE
        }
      }
    case VIDEO_LOAD_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          item: action.item,
          message: null,
          status: SUCCESS
        }
      }
    case VIDEOS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        status: FAILURE
      }
    case VIDEOS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case VIDEOS_LIST_SUCCESS:
      let categories = []
      action.list.forEach(video => {
        categories = categories.concat(...video.categories)
      })
      return {
        ...state,
        categories: uniq(categories).sort(),
        isFetching: false,
        list: action.list,
        message: null,
        status: SUCCESS
      }
    default:
      return state
  }
}
