import React, { useCallback, useEffect, useState } from 'react'

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition
} from '@atlaskit/modal-dialog'

import { useCookies } from 'react-cookie'

export default function PrivacyPolicyModal() {
  const [cookies, setCookie] = useCookies([
    'OptanonAlertBoxClosed',
    'MSA_accepted_terms_and_privacy'
  ])

  const [isOpen, setIsOpen] = useState(false)
  const openModal = useCallback(() => setIsOpen(true), [])
  const closeModal = useCallback(() => setIsOpen(false), [])
  const [privacyCheck, setPrivacyCheck] = useState(false)
  const [termsCheck, setTermsCheck] = useState(false)

  const acceptTermsAndPrivacy = () => {
    const sixMonthMaxAge = 60 * 60 * 24 * 180
    setCookie('MSA_accepted_terms_and_privacy', true, { maxAge: sixMonthMaxAge })
    closeModal()
  }

  useEffect(() => {
    const excludedPaths = ['/terms', '/privacy-policy']
    const readingTerms = excludedPaths.includes(window.location.pathname)

    if (!readingTerms) {
      if (cookies.OptanonAlertBoxClosed && !cookies.MSA_accepted_terms_and_privacy) {
        openModal()
      }
    }
  }, [cookies.OptanonAlertBoxClosed, cookies.MSATermsAcceptDate, window.location.pathname])

  return (
    <ModalTransition>
      {isOpen && (
        /* eslint-disable-next-line jsx-a11y/no-autofocus*/
        <Modal autoFocus={false}>
          <ModalHeader>
            <ModalTitle>Terms And Conditions & Privacy Policy</ModalTitle>
          </ModalHeader>
          <ModalBody>
            Please read and indicate that you agree to our
            <a href="/terms">Terms And Conditions</a> &<a href="/privacy-policy">Privacy Policy</a>{' '}
            before using the site.
            <br />
            <br />
            <div>
              <label htmlFor="terms">
                <input
                  type="checkbox"
                  id="scales"
                  name="terms"
                  checked={privacyCheck}
                  onChange={() => setPrivacyCheck(!privacyCheck)}
                />
                I agree to the Terms and Conditions
              </label>
            </div>
            <div>
              <label htmlFor="privacy">
                <input
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  checked={termsCheck}
                  onChange={() => setTermsCheck(!termsCheck)}
                />
                I agree to the Privacy Policy
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              appearance="primary"
              disabled={!termsCheck || !privacyCheck}
              onClick={acceptTermsAndPrivacy}
            >
              Continue
            </button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}
