import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import calculators from './calculators.json'
import { logRequest } from 'actions/requests'
import { FEATURE_TYPE } from './constants'

export const Calculator = ({ calculatorId, logRequest }) => {
  const { calcXMLId, category, title } = calculators[calculatorId]
  const [isLoading, setIsLoading] = useState(true)

  const getCalcUrl = calcId =>
    `https://www.calcxml.com/calculators/${calcId}?skn=824&r=2&tabs=1&secure=1`

  useEffect(() => {
    const calcEl = document.createElement('object')
    calcEl.onload = () => setIsLoading(false)
    calcEl.data = getCalcUrl(calcXMLId)
    calcEl.setAttribute('id', 'rendered-calc')
    document.getElementById('calc-target-div').appendChild(calcEl)

    logRequest({
      feature: FEATURE_TYPE,
      category: category,
      item: title,
      action: 'view'
    })

    return () => {
      calcEl.remove()
    }
  }, [calcXMLId])

  return (
    <>
      <Link to={'/calculators'} id={'calculator-back'}>
        Back
      </Link>
      <div id={'calc-target-div'}>
        {isLoading && (
          <div id={'loading-div'}>
            <img id={'spinner'} src="../img/loading.gif" alt="Loading" />
          </div>
        )}
      </div>
    </>
  )
}

Calculator.propTypes = {
  calculatorId: PropTypes.string.isRequired,
  logRequest: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logRequest
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Calculator)
