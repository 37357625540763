import React from 'react'
import PropTypes from 'prop-types'
import history from 'services/history'

const CoachingLink = ({ brand, compact }) => (
  <div className="coaching-link">
    <div className="coaching-link-container">
      <div className="coaching-link-content">
        <h2>Talk With a Money Coach</h2>
        <button
          className="button"
          onClick={() => history.push('/coaching')}
          style={{
            backgroundColor: brand.colors.buttonBackgroundColor,
            color: brand.colors.buttonTextColor
          }}
        >
          Schedule
        </button>
      </div>
    </div>
  </div>
)

CoachingLink.propTypes = {
  brand: PropTypes.object.isRequired,
  compact: PropTypes.bool
}

export default CoachingLink
