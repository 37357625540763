import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { logRequest } from 'actions/requests'

// feature: financial_resources
export class Coaching extends Component {
  coachingHeader = React.createRef()

  componentDidMount() {
    document.title = 'Coaching - MSA Members'
    const el = this.coachingHeader.current
    this.handleFocus(el)

    const { feature, logRequest } = this.props
    logRequest({
      feature,
      action: 'view'
    })
  }

  handleFocus = element => {
    if (element) {
      element.focus()
      element.style.outline = 'none'
    }
  }

  render() {
    const { brand, feature, features } = this.props
    const currentFeature = features.list.length ? features.list.find(f => f.code === feature) : null

    return (
      <div className="coaching">
        <div className="banner">
          <p>{currentFeature && currentFeature.summary}</p>
        </div>
        <div className="container">
          <h2
            style={{ color: brand.colors.headerTextColor }}
            ref={this.coachingHeader}
            tabIndex="-1"
          >
            Your Coaching Benefit
          </h2>
          <p>{currentFeature && currentFeature.description}</p>
          <button
            className="button phone"
            style={{
              backgroundColor: brand.colors.buttonBackgroundColor,
              color: brand.colors.buttonTextColor
            }}
          >
            <a
              href={`tel: +1-${brand.phone}`}
              aria-label={`Financial coaching phone number ${brand.phone}`}
            >
              {brand.phone}
            </a>
          </button>
        </div>
      </div>
    )
  }
}

Coaching.propTypes = {
  brand: PropTypes.object.isRequired,
  feature: PropTypes.string.isRequired,
  features: PropTypes.object.isRequired,
  logRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  brand: state.brand,
  features: state.features
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logRequest
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Coaching)
