import {
  FEATURES_LIST_FAILURE,
  FEATURES_LIST_REQUEST,
  FEATURES_LIST_SUCCESS
} from 'constants/actionTypes'

import filter from 'lodash/filter'

import { FEATURE_LINKS } from 'constants/app'
import { msaApi } from 'services/apis'

export const loadFeatures = () => {
  return async dispatch => {
    dispatch({
      type: FEATURES_LIST_REQUEST
    })

    const [error, features] = await msaApi.get('features?activeOnly=true')

    if (!error) {
      dispatch({
        type: FEATURES_LIST_SUCCESS,
        list: filter(features, feature => FEATURE_LINKS.hasOwnProperty(feature.code))
      })
    } else {
      dispatch({
        type: FEATURES_LIST_FAILURE,
        message: error
      })
    }
  }
}

export const loadFeaturesIfNeeded = () => {
  return async (dispatch, getState) => {
    const features = getState().features

    if (!features.list.length) {
      return dispatch(loadFeatures())
    }
  }
}
