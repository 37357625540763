// admin state
export const IMPORT_ARTICLES_REQUEST = 'IMPORT_ARTICLES_REQUEST'
export const IMPORT_ARTICLES_SUCCESS = 'IMPORT_ARTICLES_SUCCESS'
export const IMPORT_ARTICLES_FAILURE = 'IMPORT_ARTICLES_FAILURE'

// app state
export const APP_STATE_UPDATE = 'APP_STATE_UPDATE'
export const APP_STATE_RESET = 'APP_STATE_RESET'

// articles
export const ARTICLE_CATEGORIES_UPDATE = 'ARTICLE_CATEGORIES_UPDATE'
export const ARTICLE_CATEGORY_UPDATE = 'ARTICLE_CATEGORY_UPDATE'
export const ARTICLE_LOAD_FAILURE = 'ARTICLE_LOAD_FAILURE'
export const ARTICLE_LOAD_SUCCESS = 'ARTICLE_LOAD_SUCCESS'
export const ARTICLES_LIST_FAILURE = 'ARTICLES_LIST_FAILURE'
export const ARTICLES_LIST_REQUEST = 'ARTICLES_LIST_REQUEST'
export const ARTICLES_LIST_SUCCESS = 'ARTICLES_LIST_SUCCESS'
export const ARTICLES_PAGE_UPDATE = 'ARTICLES_PAGE_UPDATE'

// brand
export const BRAND_FAILURE = 'BRAND_FAILURE'
export const BRAND_REQUEST = 'BRAND_REQUEST'
export const BRAND_RESET = 'BRAND_RESET'
export const BRAND_SUCCESS = 'BRAND_SUCCESS'

// calculators
export const CALCULATOR_FAILURE = 'CALCULATOR_FAILURE'
export const CALCULATOR_REQUEST = 'CALCULATOR_REQUEST'
export const CALCULATOR_SUCCESS = 'CALCULATOR_SUCCESS'
export const CALCULATOR_RESET = 'CALCULATOR_RESET'
export const CALCULATORS_LIST_FAILURE = 'CALCULATORS_LIST_FAILURE'
export const CALCULATORS_LIST_REQUEST = 'CALCULATORS_LIST_REQUEST'
export const CALCULATORS_LIST_SUCCESS = 'CALCULATORS_LIST_SUCCESS'

// contact
export const CONTACT_SEND_FAILURE = 'CONTACT_SEND_FAILURE'
export const CONTACT_SEND_REQUEST = 'CONTACT_SEND_REQUEST'
export const CONTACT_SEND_RESET = 'CONTACT_SEND_RESET'
export const CONTACT_SEND_SUCCESS = 'CONTACT_SEND_SUCCESS'

// features
export const FEATURES_LIST_FAILURE = 'FEATURES_LIST_FAILURE'
export const FEATURES_LIST_REQUEST = 'FEATURES_LIST_REQUEST'
export const FEATURES_LIST_SUCCESS = 'FEATURES_LIST_SUCCESS'

// questions
export const QUESTIONS_LIST_FAILURE = 'QUESTIONS_LIST_FAILURE'
export const QUESTIONS_LIST_REQUEST = 'QUESTIONS_LIST_REQUEST'
export const QUESTIONS_LIST_SUCCESS = 'QUESTIONS_LIST_SUCCESS'

// scores
export const SCORES_ADD = 'SCORES_ADD'
export const SCORES_CURRENT_ADD = 'SCORES_CURRENT_ADD'
export const SCORES_RESET = 'SCORES_RESET'
export const SCORES_SAVE_FAILURE = 'SCORES_SAVE_FAILURE'
export const SCORES_SAVE_REQUEST = 'SCORES_SAVE_REQUEST'
export const SCORES_SAVE_SUCCESS = 'SCORES_SAVE_SUCCESS'

// videos
export const VIDEO_CATEGORY_UPDATE = 'VIDEO_CATEGORY_UPDATE'
export const VIDEO_LOAD_FAILURE = 'VIDEO_LOAD_FAILURE'
export const VIDEO_LOAD_SUCCESS = 'VIDEO_LOAD_SUCCESS'
export const VIDEOS_LIST_FAILURE = 'VIDEOS_LIST_FAILURE'
export const VIDEOS_LIST_REQUEST = 'VIDEOS_LIST_REQUEST'
export const VIDEOS_LIST_SUCCESS = 'VIDEOS_LIST_SUCCESS'
