import Api from 'services/Api'

export const msaApi = new Api({
  baseUrl: `${process.env.REACT_APP_MSA_API_URL}/v1`,
  includeBearer: true
})

export const vimeoApi = new Api({
  baseUrl: 'https://vimeo.com/api/oembed.json?url=',
  includeBearer: false
})
