export const SESSION_TIMEOUT_HOURS = 4

export const FEATURE_LINKS = {
  financial_checkup: { link: '/checkup', label: 'Checkup' },
  financial_videos: { link: '/videos', label: 'Videos' },
  financial_tools: { link: '/calculators', label: 'Calculators' },
  financial_library: { link: '/articles', label: 'Articles' },
  financial_resources: { link: '/coaching', label: 'Coaching', style: 'special' }
}

export const ROOT_PHONE_NUMBER = '888-724-2326'

export const FAILURE = 'FAILURE'
export const SUCCESS = 'SUCCESS'

export const PHONE_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const PFW_SCORE_INDICATOR = {
  1: 'an overwhelming level of financial distress',
  2: 'an extremely high level of financial distress',
  3: 'a very high level of financial distress',
  4: 'a high level of financial distress',
  5: 'an average level of financial distress',
  6: 'a moderate level of financial distress',
  7: 'a low level of financial distress',
  8: 'a very low level of financial distress',
  9: 'an extremely low level of financial distress',
  10: 'no level of financial distress'
}

export const ARTICLES_PER_PAGE = 5
export const ARTICLES_DEFAULT_CATEGORY = 'All'
export const ARTICLES_DEFAULT_CATEGORY_CODE = 'all'

export const VIDEO_DEFAULT_CATEGORY = 'Budget'
export const VIDEO_DEFAULT_CATEGORY_CODE = 'budget'
export const VIDEO_DEFAULT_TOC_NAME = 'Introduction'
