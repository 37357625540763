import React, { useEffect } from 'react'
import Markdown from 'markdown-to-jsx/dist/index.js'
import raw from 'raw.macro'

const Terms = () => {
  const termsAndConditionsMarkdown = raw('../constants/TERMS_AND_CONDITIONS.md')

  useEffect(() => {
    document.title = 'Terms and Conditions - MSA Members'
  }, [])

  return (
    <div className="container-content terms">
      <Markdown>{termsAndConditionsMarkdown}</Markdown>
    </div>
  )
}
export default Terms
