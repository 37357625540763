import React from 'react'
import PropTypes from 'prop-types'

import { CategoryLinks } from 'components/'
const Categories = ({ brand, entity, items }) => (
  <div className="categories">
    <h2>Categories</h2>
    <CategoryLinks brand={brand} entity={entity} items={items}/>
  </div>
)

Categories.propTypes = {
  brand: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

export default Categories
