import queryString from 'query-string'

import {
  APP_STATE_UPDATE,
  APP_STATE_RESET,
  BRAND_FAILURE,
  BRAND_REQUEST,
  BRAND_SUCCESS
} from 'constants/actionTypes'

import { loadFeaturesIfNeeded } from 'actions/features'
import { logRequest } from 'actions/requests'
import { msaApi } from 'services/apis'
import history from 'services/history'
import { clearState, loadSession, saveState } from 'services/localStorage'

export const launchApp = (tokenId, search) => {
  return async (dispatch, getState) => {
    // check for a url param in the referenceIds
    const referenceIds = queryString.parse(search)
    let url = '/'
    if (referenceIds.url) {
      url = referenceIds.url
      delete referenceIds.url // we don't want to save the url in referenceIds
    }

    // we have to dispatch this first, so we can make subsequent API calls
    dispatch({
      type: APP_STATE_UPDATE,
      payload: { token: tokenId, referenceIds }
    })

    // load the token to get the organizationId
    const [error, token] = await msaApi.get(`tokens/${tokenId}`)
    if (!error) {
      dispatch({
        type: APP_STATE_UPDATE,
        payload: { orgId: token.organizationId }
      })
      saveState(getState().app)

      // load the brand for the organization
      dispatch({ type: BRAND_REQUEST })
      const [brandError, brand] = await msaApi.get(`organizations/${token.organizationId}/brand`)

      if (!brandError) {
        // this is to ensure that features have been loaded before rendering the App
        return Promise.all([
          dispatch({
            type: BRAND_SUCCESS,
            payload: brand
          }),
          dispatch(loadFeaturesIfNeeded())
        ]).then(() => {
          checkSession(dispatch)
          // everything we need is loaded, send them to their desired destination
          history.push(url)
        })
      } else {
        dispatch({
          type: BRAND_FAILURE,
          message: error
        })
      }
    } else {
      dispatch({
        type: APP_STATE_RESET
      })
      clearState()

      // should send them to the not authorized page
      history.push('/')
    }
  }
}

export const loadAppState = ({ orgId }, redirect) => {
  return async dispatch => {
    // load the brand for the organization
    dispatch({ type: BRAND_REQUEST })
    const [error, brand] = await msaApi.get(`organizations/${orgId}/brand`)

    if (!error) {
      // this is to ensure that features have been loaded before rendering the App
      return Promise.all([
        dispatch({
          type: BRAND_SUCCESS,
          payload: brand
        }),
        dispatch(loadFeaturesIfNeeded())
      ]).then(() => {
        checkSession(dispatch)
        // check for a desired destination in the query string (url=foo)
        if (redirect) {
          const params = queryString.parse(window.location.search)
          const url = params.url ? params.url : '/'
          history.push(url)
        }
      })
    } else {
      dispatch({
        type: BRAND_FAILURE,
        message: error
      })
    }
  }
}

export const resetAppState = () => {
  return {
    type: APP_STATE_RESET
  }
}

export const checkSession = dispatch => {
  const isNewSession = loadSession()
  if (isNewSession) {
    dispatch(
      logRequest({
        feature: 'session',
        action: 'new'
      })
    )
  }
}
