import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { CalculatorList, Calculator } from '../components'

export class Calculators extends Component {
  componentDidMount() {
    document.title = 'Calculators - MSA Members'
  }
  render() {
    const { id } = this.props.match.params
    return (
      <div className={'calculator-container'}>
        {!id ? <CalculatorList /> : <Calculator calculatorId={id} />}
      </div>
    )
  }
}

export default withRouter(Calculators)
