import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Question } from 'components/'

class Questions extends Component {
  state = {
    disabled: true,
    selectedValue: 0
  }

  componentDidMount() {
    this.handleFocus()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scores.current !== this.props.scores.current) {
      this.setState({ disabled: true, selectedValue: 0 })
    }
  }

  handleAddScore = () => {
    const { onAddScore, questions, scores } = this.props
    const { selectedValue } = this.state
    const save = scores.current === questions.list.length - 1

    onAddScore(questions.list[scores.current], selectedValue, save)
    !save && this.handleFocus()
  }

  handleSelectScore = selectedValue => {
    this.setState({ disabled: false, selectedValue })
  }

  handleFocus = () => {
    const el = document.getElementById('questionHeader')
    if (el) {
      el.focus()
      el.style.outline = 'none'
    }
  }

  render() {
    const { brand, questions, scores } = this.props
    const { disabled, selectedValue } = this.state
    const currentQuestion = questions.list[scores.current]

    return (
      <div className="questions-container">
        <div className="questions">
          <Question
            brand={brand}
            onSelectScore={this.handleSelectScore}
            selectedValue={selectedValue}
            question={currentQuestion}
          />
        </div>
        <div className="action">
          <button
            className="button"
            disabled={disabled || scores.isSaving}
            onClick={this.handleAddScore}
            style={{
              backgroundColor: brand.colors.buttonBackgroundColor,
              color: brand.colors.buttonTextColor
            }}
          >
            {scores.current < questions.list.length - 1 ? 'Next' : 'Submit'}
          </button>
        </div>
      </div>
    )
  }
}

Questions.propTypes = {
  brand: PropTypes.object.isRequired,
  onAddScore: PropTypes.func.isRequired,
  scores: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired
}

export default Questions
