import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TableOfContentsLinks } from 'components/'

class TableOfContents extends Component {
  render() {
    const { brand, category, items } = this.props

    return (
      <div className="table-of-contents">
        <h2>Table of Contents</h2>
        <TableOfContentsLinks brand={brand} category={category} items={items} />
      </div>
    )
  }
}

TableOfContents.propTypes = {
  brand: PropTypes.object.isRequired,
  category: PropTypes.string,
  items: PropTypes.object.isRequired
}

export default TableOfContents
