import { APP_STATE_UPDATE, APP_STATE_RESET } from 'constants/actionTypes'

const initialState = {
  orgId: '',
  referenceIds: '',
  token: ''
}

export default function app(state = initialState, action) {
  switch (action.type) {
    case APP_STATE_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case APP_STATE_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
