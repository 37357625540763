import React, { useEffect } from 'react'
import Markdown from 'markdown-to-jsx/dist/index.js'
import raw from 'raw.macro'

const Accessibility = () => {
  const accessibilityMarkdown = raw('../constants/ACCESSIBILITY.md')

  useEffect(() => {
    document.title = 'Accessibility - MSA Members'
  }, [])
  return <Markdown>{accessibilityMarkdown}</Markdown>
}
export default Accessibility
