import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Media from 'react-media'
import find from 'lodash/find'
import isUndefined from 'lodash/isUndefined'

import { logRequest } from 'actions/requests'
import { loadVideo, loadVideosIfNeeded } from 'actions/videos'
import {
  Categories,
  CategoriesMenu,
  CoachingLink,
  TableOfContents,
  TableOfContentsMenu,
  UpNext,
  Video
} from 'components/'
import { VIDEO_DEFAULT_CATEGORY_CODE } from 'constants/app'
import { unslugify } from 'services/format'
import history from 'services/history'

// feature: financial_videos
export class Videos extends Component {
  state = {
    isCategoryMenuOpen: false
  }
  componentDidMount() {
    document.title = 'Videos - MSA Members'
    const {
      feature,
      logRequest,
      loadVideosIfNeeded,
      match: {
        params: { category, id }
      }
    } = this.props

    if (!category) {
      // redirect to the default category, if one was not provided
      history.replace(`/videos/${VIDEO_DEFAULT_CATEGORY_CODE}`)
    } else {
      logRequest({
        feature,
        category: category || undefined,
        item: id || undefined,
        action: 'view'
      })

      loadVideosIfNeeded(feature, category, id)
    }
  }

  componentDidUpdate(prevProps) {
    const topics = document.getElementsByClassName('topic').item(0)
    const el = document.getElementById('videoPlayerId')

    const prevVideo = prevProps.video.item.id
    const newVideo = this.props.video.item.id
    const prevCatagory = prevProps.video.category
    const newCatagory = this.props.video.category

    if (el && topics) {
      if (prevCatagory !== newCatagory) {
        topics.setAttribute('tabindex', '-1')
        this.handleFocus(topics)
      } else if (newVideo !== prevVideo) {
        topics.removeAttribute('tabindex')
        this.handleFocus(el)
      }
    }
  }

  handleMenuToggle = isCategoryMenuOpen => {
    this.setState({ isCategoryMenuOpen })
  }
  handleFocus = element => {
    if (element) {
      element.focus()
      element.style.outline = 'none'
    }
  }

  render() {
    const {
      brand,
      categories,
      hasCoaching,
      match: {
        params: { category }
      },
      video
    } = this.props
    const { isCategoryMenuOpen } = this.state
    const title = `${unslugify(video.category)} Lessons`

    return (
      <Media query="(min-width: 830px)">
        {matches =>
          matches ? (
            <div className="videos">
              <TableOfContents brand={brand} category={category} items={video.tableOfContents} />
              <div className="video-container">
                <Video
                  brand={brand}
                  title={title}
                  video={video.item}
                  videoPlayerId="videoPlayerId"
                />
                <UpNext brand={brand} current={video} items={video.tableOfContents} />
              </div>
              <div className="right-side-nav">
                <Categories brand={brand} entity="videos" items={categories} />
                {hasCoaching && <CoachingLink brand={brand} />}
              </div>
            </div>
          ) : (
            <div className="videos compact">
              <CategoriesMenu
                brand={brand}
                entity="videos"
                items={categories}
                onMenuToggle={this.handleMenuToggle}
                title={title}
              />
              <div className="video-container">
                <Video
                  brand={brand}
                  categories={categories}
                  compact={true}
                  isCategoryMenuOpen={isCategoryMenuOpen}
                  title={title}
                  video={video.item}
                />
                <UpNext brand={brand} current={video} items={video.tableOfContents} />
              </div>
              <TableOfContentsMenu
                brand={brand}
                category={category}
                items={video.tableOfContents}
              />
              {hasCoaching && <CoachingLink brand={brand} />}
            </div>
          )
        }
      </Media>
    )
  }
}

Videos.propTypes = {
  brand: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  feature: PropTypes.string.isRequired,
  features: PropTypes.object.isRequired,
  hasCoaching: PropTypes.bool.isRequired,
  logRequest: PropTypes.func.isRequired,
  loadVideo: PropTypes.func.isRequired,
  loadVideosIfNeeded: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string,
      id: PropTypes.string
    })
  }),
  video: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  const hasCoaching = find(state.features.list, { code: 'financial_resources' })

  return {
    brand: state.brand,
    categories: state.videos.categories,
    features: state.features,
    hasCoaching: !isUndefined(hasCoaching),
    video: state.videos.current
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logRequest,
      loadVideo,
      loadVideosIfNeeded
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Videos))
