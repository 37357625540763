import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { calculatorGroups } from './calculators'
import { logRequest } from 'actions/requests'
import { FEATURE_TYPE } from './constants'

const CATEGORY_HALF_WAY_POINT = 3

const columns = [
  Object.entries(calculatorGroups).slice(0, CATEGORY_HALF_WAY_POINT),
  Object.entries(calculatorGroups).slice(CATEGORY_HALF_WAY_POINT)
]

export const CalculatorList = ({ logRequest }) => {
  useEffect(() => {
    logRequest({
      feature: FEATURE_TYPE,
      item: 'calculator_list',
      action: 'view'
    })
  }, [])

  return (
    <div className={'calculators-list'}>
      {columns.map(col => (
        <div key={col[0][0]}>
          {col.map(([category, calculators]) => (
            <div key={category} className={'calculator-card'}>
              <h4>{category}</h4>
              {calculators.map(calc => (
                <Link key={calc.title} to={`/calculators/${calc.id}`}>
                  {calc.title}
                </Link>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

CalculatorList.propTypes = {
  logRequest: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logRequest
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(CalculatorList)
