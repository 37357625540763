import {
  IMPORT_ARTICLES_REQUEST,
  IMPORT_ARTICLES_SUCCESS,
  IMPORT_ARTICLES_FAILURE
} from 'constants/actionTypes'
import { msaApi } from 'services/apis'

export const importArticles = () => {
  return async dispatch => {
    // load the brand for the organization
    dispatch({ type: IMPORT_ARTICLES_REQUEST })
    const [error, results] = await msaApi.post(`features/financial_library/import`, {
      overwrite: true
    })

    if (!error) {
      // this is to ensure that features have been loaded before rendering the App
      dispatch({
        type: IMPORT_ARTICLES_SUCCESS,
        message: `Import Successful`,
        results
      })
    } else {
      dispatch({
        type: IMPORT_ARTICLES_FAILURE,
        message: `Import Failed (${error})`
      })
    }
  }
}
