import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => (
  <footer className="msa-footer">
    <div className="footer">
      <div className="links">
        <span>
          <Link to="/">Home</Link>
        </span>
        <span>
          <Link to="/terms">Terms And Conditions</Link>
        </span>
        <span>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </span>
        <span>
          <Link to="/accessibility">Accessibility Statement</Link>
        </span>
      </div>
      <span className="footer">
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookies Settings
        </button>
      </span>
      <div className="copyright">&copy;{new Date().getFullYear()} My Secure Advantage</div>
    </div>
  </footer>
)

export default Footer
