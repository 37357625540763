import React, { useEffect } from 'react'
import Markdown from 'markdown-to-jsx/dist/index.js'
import raw from 'raw.macro'

const PrivacyPolicy = () => {
  const privacyPolicyMarkdown = raw('../constants/PRIVACY_POLICY.md')

  useEffect(() => {
    document.title = 'Privacy Policy - MSA Members'
  }, [])

  return (
    <div className="container-content privacy-policy">
      <Markdown>{privacyPolicyMarkdown}</Markdown>
    </div>
  )
}
export default PrivacyPolicy
