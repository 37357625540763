import React from 'react'

const NotAuthorized = () => (
  <div
    className="not-authorized"
    style={{ display: 'flex', margin: '40px auto', padding: 15, maxWidth: 600, width: '100%' }}
  >
    <div>
      <h1>Not Authorized</h1>
      <p>Oops! Something doesn't look right.</p>
      <p>Please access this site by clicking on the link from your employers site.</p>
    </div>
  </div>
)

export default NotAuthorized
