import 'styles/app.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'

import history from 'services/history'

import {
  Accessibility,
  Footer,
  Header,
  NotAuthorized,
  NotFound,
  ScrollToTop,
  Terms,
  PrivacyPolicy
} from 'components/'
import {
  Admin,
  Articles,
  Calculators,
  Checkup,
  Coaching,
  Contact,
  Home,
  Launch,
  Videos
} from 'containers/'

const withProps = (MyComponent, props) => {
  return function(matchProps) {
    return <MyComponent {...props} {...matchProps} />
  }
}

export class App extends Component {
  render() {
    const { app, brand, features } = this.props

    return (
      <Router history={history}>
        <ScrollToTop>
          <div className="msa-container">
            <div id="skip">
              <a href="#content">Skip to main content</a>
            </div>
            <Switch>
              <Route path="/launch/:token" component={Launch} />
              <Route
                exact
                path="/admin/rss/update"
                component={withProps(Admin, { action: 'import_articles' })}
              />
              <Route
                path="/"
                render={() => {
                  if (!app || (app && !app.token)) {
                    return <NotAuthorized />
                  } else {
                    return (
                      <React.Fragment>
                        <Header brand={brand} features={features} />
                        <div className="msa-content" id="msa-content">
                          <div className="content" id="content">
                            <Switch>
                              <Route
                                path="/articles/:category?/:id?"
                                component={withProps(Articles, {
                                  feature: 'financial_library'
                                })}
                              />
                              <Route
                                path="/calculators/:id?"
                                component={withProps(Calculators, {
                                  feature: 'financial_tools'
                                })}
                              />
                              <Route
                                path="/checkup"
                                component={withProps(Checkup, {
                                  feature: 'financial_checkup'
                                })}
                              />
                              <Route
                                path="/coaching"
                                component={withProps(Coaching, {
                                  feature: 'financial_resources'
                                })}
                              />
                              <Route path="/accessibility" component={Accessibility} />
                              <Route path="/contact" component={Contact} />
                              <Route path="/terms" component={Terms} />
                              <Route path="/privacy-policy" component={PrivacyPolicy} />
                              <Route
                                path="/videos/:category?/:id?"
                                component={withProps(Videos, {
                                  feature: 'financial_videos'
                                })}
                              />
                              <Route path="/" exact component={Home} />
                              <Route path="*" component={NotFound} />
                            </Switch>
                          </div>
                          <Footer />
                        </div>
                      </React.Fragment>
                    )
                  }
                }}
              />
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
    )
  }
}

App.propTypes = {
  app: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  app: state.app,
  brand: state.brand,
  features: state.features.list
})

export default connect(mapStateToProps)(App)
