import {
  IMPORT_ARTICLES_REQUEST,
  IMPORT_ARTICLES_SUCCESS,
  IMPORT_ARTICLES_FAILURE
} from 'constants/actionTypes'
import { FAILURE, SUCCESS } from 'constants/app'

const initialState = {
  isFetching: false,
  message: '',
  importResults: {},
  status: null
}

export default function admin(state = initialState, action) {
  switch (action.type) {
    case IMPORT_ARTICLES_REQUEST:
      return {
        ...state,
        importResults: {},
        isFetching: true
      }
    case IMPORT_ARTICLES_SUCCESS:
      return {
        ...state,
        importResults: action.results,
        isFetching: false,
        message: action.message,
        status: SUCCESS
      }
    case IMPORT_ARTICLES_FAILURE:
      return {
        ...state,
        importResults: {},
        isFetching: false,
        message: action.message,
        status: FAILURE
      }
    default:
      return state
  }
}
