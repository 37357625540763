import {
  QUESTIONS_LIST_FAILURE,
  QUESTIONS_LIST_REQUEST,
  QUESTIONS_LIST_SUCCESS
} from 'constants/actionTypes'

import { msaApi } from 'services/apis'

export const loadQuestions = () => {
  return async dispatch => {
    dispatch({
      type: QUESTIONS_LIST_REQUEST
    })

    const [error, questions] = await msaApi.get('questions')

    if (!error) {
      dispatch({
        type: QUESTIONS_LIST_SUCCESS,
        list: questions
      })
    } else {
      dispatch({
        type: QUESTIONS_LIST_FAILURE,
        message: error
      })
    }
  }
}

export const loadQuestionsIfNeeded = () => {
  return async (dispatch, getState) => {
    const questions = getState().questions

    if (!questions.list.length) {
      dispatch(loadQuestions())
    }
  }
}
