import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Pagination } from 'components/'
import { ARTICLES_PER_PAGE } from 'constants/app'

class List extends Component {
  state = {
    articles: []
  }
  headerRef = React.createRef()

  componentDidMount() {
    this.setPageView(this.props.items, this.props.page)
    if (this.props.category !== "all" && !this.props.compact) {
      const el = this.headerRef.current
      el.focus()
      el.style.outline = "none"
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setPageView(this.props.items, this.props.page)
    }
  }

  setPageView = (items, page) => {
    this.setState({
      articles: [...items].splice((page - 1) * ARTICLES_PER_PAGE, ARTICLES_PER_PAGE)
    })
  }

  render() {
    const { brand, category, compact, items, onPageChange, page } = this.props
    const { articles } = this.state

    return (
      <div className="article-list">
        {!compact && <h1 tabIndex="-1" ref={this.headerRef}>Recent Posts</h1>}
        <div>
          {!articles.length && <div className="article-item">No Articles Found</div>}
          {articles.map(article => (
            <div className="article-item" key={article.id}>
              {article.image && (
                <div className="cover-image">
                  <Link
                    to={`/articles/${category}/${article.code}`}
                    aria-hidden="true"
                    className="image-link"
                  >
                    <img src={article.image} className="" alt="" />
                  </Link>
                </div>
              )}
              <h3 style={{ color: brand.colors.headerTextColor }}>
                <Link to={`/articles/${category}/${article.code}`} className="title-link">
                  {article.title}
                </Link>
              </h3>
              <div>{article.summary}</div>
              <div className="read-more">
                <Link to={`/articles/${category}/${article.code}`} className="read-more">
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          brand={brand}
          onPageChange={onPageChange}
          page={page}
          totalPages={Math.ceil(items.length / ARTICLES_PER_PAGE)}
        />
      </div>
    )
  }
}

List.propTypes = {
  brand: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
}

export default List
