import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
const ARTICLE_HEADER = "articleHeader"
const Article = ({ brand, entity, item }) => {
  useEffect(() => {
    const el = document.getElementById(ARTICLE_HEADER);
    el.focus() 
    el.style.outline = "none"
  })
  return (
    <div className="article">
      {item.image && (
        <div className="cover-image">
          <img src={item.image} className="" alt=""/>
        </div>
      )}
      <h1 style={{ color: brand.colors.headerTextColor }} tabIndex="-1" id={ARTICLE_HEADER}>
        {item.title}
      </h1>
      {ReactHtmlParser(item.description)}
    </div>
  )
}

Article.propTypes = {
  item: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired
}

export default Article
