import {
  SCORES_ADD,
  SCORES_CURRENT_ADD,
  SCORES_RESET,
  SCORES_SAVE_FAILURE,
  SCORES_SAVE_REQUEST,
  SCORES_SAVE_SUCCESS
} from 'constants/actionTypes'
import { FAILURE, SUCCESS } from 'constants/app'

const initialState = {
  current: 0,
  isSaving: false,
  list: [],
  message: null,
  results: null,
  status: null
}

export default function scores(state = initialState, action) {
  switch (action.type) {
    case SCORES_ADD:
      return {
        ...state,
        list: [...state.list, action.score]
      }
    case SCORES_CURRENT_ADD:
      return {
        ...state,
        current: state.current + 1
      }
    case SCORES_RESET:
      return initialState
    case SCORES_SAVE_FAILURE:
      return {
        ...state,
        isSaving: false,
        message: action.message,
        status: FAILURE
      }
    case SCORES_SAVE_REQUEST:
      return {
        ...state,
        isSaving: true
      }
    case SCORES_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        results: action.results,
        status: SUCCESS
      }
    default:
      return state
  }
}
