import {
  VIDEO_CATEGORY_UPDATE,
  VIDEO_LOAD_FAILURE,
  VIDEO_LOAD_SUCCESS,
  VIDEOS_LIST_FAILURE,
  VIDEOS_LIST_REQUEST,
  VIDEOS_LIST_SUCCESS
} from 'constants/actionTypes'
import { VIDEO_DEFAULT_CATEGORY_CODE, VIDEO_DEFAULT_TOC_NAME } from 'constants/app'
import { msaApi, vimeoApi } from 'services/apis'
import { unslugify } from 'services/format'

export const getEmbedCode = videoLink => {
  return async dispatch => {
    const [error, embed] = await vimeoApi.get(
      `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(videoLink)}`
    )

    if (!error) {
      return embed
    } else {
      console.error(error)
    }
  }
}

export const loadVideo = (code, category) => {
  return async (dispatch, getState) => {
    const videos = getState().videos
    const categoryName = unslugify(category)
    const current = videos.current
    const video = videos.list.find(v => v.code === code)

    if (video) {
      if (current.category !== category) {
        const tableOfContents = buildTableOfContents(videos.list, categoryName)
        dispatch({
          type: VIDEO_CATEGORY_UPDATE,
          payload: {
            category,
            tableOfContents
          }
        })
      }

      dispatch({
        type: VIDEO_LOAD_SUCCESS,
        item: video
      })
    } else {
      dispatch({
        type: VIDEO_LOAD_FAILURE,
        message: `Unable to load the requested video (CODE: ${code})`
      })
    }
  }
}

export const loadVideosIfNeeded = (
  featureCode,
  category = VIDEO_DEFAULT_CATEGORY_CODE,
  videoCode
) => {
  return async (dispatch, getState) => {
    let videos = getState().videos
    const features = getState().features
    const categoryName = unslugify(category)

    if (!videos.list.length) {
      // load videos, table of contents, and initial video
      const feature = features.list.find(f => f.code === featureCode)
      if (feature) {
        dispatch(loadVideos(feature.id)).then(() => {
          videos = getState().videos

          if (!videoCode) {
            const video = videos.list.find(v => {
              return v.categories.includes(categoryName) && v.name === VIDEO_DEFAULT_TOC_NAME
            })
            videoCode = video ? video.code : null
          }

          if (videoCode) {
            dispatch(loadVideo(videoCode, category))
          } else {
            console.error(`A default video was not found for category ${categoryName}`)
          }
        })
      } else {
        console.error(`A feature was not found using code ${featureCode}`)
      }
    } else {
      if (!videoCode) {
        const video = videos.list.find(v => {
          return v.categories.includes(categoryName) && v.name === VIDEO_DEFAULT_TOC_NAME
        })
        videoCode = video ? video.code : null
      }

      if (videoCode) {
        dispatch(loadVideo(videoCode, category))
      } else {
        console.error(`A default video was not found for category ${categoryName}`)
      }
    }
  }
}

/* Private functions */

const buildTableOfContents = (videos, category) => {
  const toc = {}
  const categoryVideos = videos
    .filter(v => v.categories.includes(category))
    .sort((a, b) => {
      return a.order - b.order
    })

  categoryVideos.forEach(v => {
    if (!toc[v.name]) {
      toc[v.name] = []
    }
    toc[v.name].push(v)
  })
  return toc
}

const loadVideos = parentId => {
  return async dispatch => {
    dispatch({
      type: VIDEOS_LIST_REQUEST
    })

    const [error, videos] = await msaApi.get(`features?activeOnly=true&parent_id=${parentId}`)

    if (!error) {
      return dispatch({
        type: VIDEOS_LIST_SUCCESS,
        list: videos
      })
    } else {
      return dispatch({
        type: VIDEOS_LIST_FAILURE,
        message: error
      })
    }
  }
}
