import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/features/object/entries'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import 'polyfills'
import { loadAppState } from 'actions/app'
import { App } from 'containers/'
import store from 'services/store'

const render = MyComponent => {
  ReactDOM.render(
    <Provider store={store}>
      <MyComponent />
    </Provider>,
    document.getElementById('root')
  )
}

const { pathname } = window.location
const isLaunchUrl = pathname.indexOf('/launch') > -1
const appState = store.getState().app
const isNewToken = appState.token && isLaunchUrl && pathname.indexOf(`${appState.token}`) === -1
if ((appState.token && !isLaunchUrl) || (appState.token && isLaunchUrl && !isNewToken)) {
  // use the existing info to load the app
  store.dispatch(loadAppState(appState, isLaunchUrl)).then(() => {
    render(App)
  })
} else {
  render(App)
}
