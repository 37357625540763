import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { ARTICLES_DEFAULT_CATEGORY, ARTICLES_DEFAULT_CATEGORY_CODE } from 'constants/app'
import { slugify } from 'services/format'
const CategoryLinks = ({ brand, entity, items }) => (

  <div className="category-links" >
    {entity === 'articles' && (
      <NavLink
        key={ARTICLES_DEFAULT_CATEGORY_CODE}
        to={`/${entity}/${ARTICLES_DEFAULT_CATEGORY_CODE}`}
        activeStyle={{ color: brand.colors.menuTextSelectedColor }}
        aria-label={`Visit our ${ARTICLES_DEFAULT_CATEGORY} categories page`}
      >
        <span>{ARTICLES_DEFAULT_CATEGORY}</span>
      </NavLink>
    )}
    {items.map(c => (
      <NavLink
        key={c}
        to={`/${entity}/${slugify(c)}`}
        activeStyle={{ color: brand.colors.menuTextSelectedColor }}
        aria-label={`Visit our ${c} categories page`}
      >
        <span>{c}</span>
      </NavLink>

    ))}
  </div>
)

CategoryLinks.propTypes = {
  brand: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
}

export default CategoryLinks
