import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Pagination = ({ brand, onPageChange, page, totalPages }) => {
  useEffect(() => {
    const el = document.getElementById("backBtn")
    if (el) {
      if (page === totalPages) {
        el.setAttribute("tabindex", "-1")
        el.focus()
      } else {
        el.removeAttribute("tabindex")
      }
    }
  })
  return totalPages > 1 ? (
    <div className="article-pagination">
      {page > 1 && (
        <a
          href={`#${page - 1}`}
          disabled={page === 1}
          aria-label={`Go back to page ${page - 1}`}
          onClick={se => {
            se.preventDefault()
            onPageChange(page - 1)
          }}
          id="backBtn"
        >
          <i className="material-icons" style={{ color: brand.colors.buttonBackgroundColor }}
            aria-hidden="true"
          >
            keyboard_arrow_left
          </i>
        </a>
      )}
      {page === 1 &&
        <span aria-label="Disabled back button">
          <i className="material-icons disabled" aria-hidden="true">keyboard_arrow_left</i>
        </span>
      }
      <span aria-label={`Currently on page ${page} of ${totalPages}`}>
        <span aria-hidden="true">
          {page} of {totalPages}
        </span>
      </span>
      {page < totalPages && (
        <a
          href={`#${page + 1}`}
          onClick={se => {
            se.preventDefault()
            onPageChange(page + 1)
          }}
          aria-label={`Go forward to page ${page + 1}`}>
          <i
            className="material-icons" style={{ color: brand.colors.buttonBackgroundColor }}
            aria-hidden="true">
            keyboard_arrow_right
          </i>
        </a>
      )}
      {page === totalPages &&
        <span aria-label="Disabled forward button">
          <i className="material-icons disabled" aria-hidden="true">keyboard_arrow_right</i>
        </span>
      }
    </div>
  ) : null
}

Pagination.propTypes = {
  brand: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
}

export default Pagination
