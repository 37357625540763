import {
  QUESTIONS_LIST_FAILURE,
  QUESTIONS_LIST_REQUEST,
  QUESTIONS_LIST_SUCCESS
} from 'constants/actionTypes'
import { FAILURE, SUCCESS } from 'constants/app'

const initialState = {
  isFetching: false,
  list: [],
  message: null,
  status: null
}

export default function questions(state = initialState, action) {
  switch (action.type) {
    case QUESTIONS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        status: FAILURE
      }
    case QUESTIONS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.list,
        status: SUCCESS
      }
    default:
      return state
  }
}
