import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import findIndex from 'lodash/findIndex'

import { slugify } from 'services/format'

class UpNext extends Component {
  state = {
    count: 3,
    displayItems: []
  }
  componentDidMount() {
    this.setUpNextDisplay(this.props.current, this.props.items)
    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    const { current, items } = this.props

    if (current.item !== prevProps.current.item) {
      this.setUpNextDisplay(current, items)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = debounce(() => {
    this.setUpNextDisplay(this.props.current, this.props.items)
  }, 500)

  setUpNextDisplay(current, items) {
    if (Object.keys(items).length && Object.keys(current.item).length) {
      const allItems = []
      const displayItems = []
      let count = 3

      // change the number of up next videos we show based on screen size
      if (window.matchMedia('(min-width: 830px) and (max-width: 1030px)').matches) {
        count = 2
      } else if (window.matchMedia('(min-width: 400px) and (max-width: 629px)').matches) {
        count = 2
      } else if (window.matchMedia('(max-width: 399px)').matches) {
        count = 1
      }

      Object.keys(items).forEach(v => {
        allItems.push(...items[v])
      })

      const idx = findIndex(allItems, v => v.id === current.item.id)
      for (let x = idx + 1, i = 0; x < allItems.length && i < count; x++, i++) {
        displayItems.push(allItems[x])
      }

      this.setState({
        count,
        displayItems
      })
    }
  }

  render() {
    const { brand, current } = this.props
    const { count, displayItems } = this.state

    // only show Up Next section if there are more videos in the lesson
    return displayItems.length ? (
      <div className="up-next">
        <h3 style={{ color: brand.colors.headerTextColor }}>Up Next</h3>
        <div className="up-next-videos">
          {displayItems.map(v => (
            <Link
              key={v.id}
              to={`/videos/${slugify(current.category)}/${v.code}`}
              aria-label={`View the ${v.title} video`}
              style={{ maxWidth: `${100 / count}%` }}
            >
              <img src={v.image} alt={`View the ${v.title} video`} />
            </Link>
          ))}
        </div>
      </div>
    ) : null
  }
}

UpNext.propTypes = {
  brand: PropTypes.object.isRequired,
  current: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired
}

export default UpNext
