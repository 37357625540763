import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { importArticles } from 'actions/admin'
import { NotAuthorized } from 'components/'

export class Admin extends Component {
  componentDidMount() {
    const { action, importArticles } = this.props

    if (action && action === 'import_articles') {
      importArticles()
    }
  }

  // TODO possibly show a "loading" placeholder state here...like Facebook?
  render() {
    return (
      <div
        className="not-authorized"
        style={{ display: 'flex', margin: '40px auto', padding: 15, maxWidth: 600, width: '100%' }}
      >
        {this.renderAction()}
      </div>
    )
  }

  renderAction() {
    const { action } = this.props

    if (action === 'import_articles') {
      return this.renderImportArticles()
    }
    return <NotAuthorized />
  }

  renderImportArticles() {
    const { admin } = this.props
    const { counts } = admin.importResults

    return admin.isFetching ? (
      <div>Importing Articles...</div>
    ) : (
      <div>
        <h1>{admin.message}</h1>
        {Object.keys(admin.importResults).length > 0 && (
          <ul>
            <li>Total: {counts.fetched}</li>
            <li>Created: {counts.created}</li>
            <li>Updated: {counts.updated}</li>
            <li>Skipped: {counts.fetched - counts.created - counts.updated}</li>
          </ul>
        )}
      </div>
    )
  }
}

Admin.propTypes = {
  action: PropTypes.string,
  importArticles: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  admin: state.admin
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      importArticles
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
