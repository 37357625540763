import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll } from '@fortawesome/free-solid-svg-icons'

export const Video = ({ brand, compact, isCategoryMenuOpen, title, video, videoPlayerId }) => {
  const vimeoId = Object.keys(video).length ? video.link.split('/').reverse()[0] : null
  return (
    <div className="video">
      {!compact && (
        <h1 tabIndex="-1" id={videoPlayerId}>
          {title}
        </h1>
      )}
      <div className="player" style={{ zIndex: isCategoryMenuOpen ? -1 : 1 }}>
        {vimeoId && (
          <iframe
            src={`https://player.vimeo.com/video/${vimeoId}?app_id=122963&dnt=1`}
            frameBorder="0"
            title={video.title}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        )}
      </div>
      <div className="title">
        <h3 style={{ color: brand.colors.headerTextColor }}>{video.title}</h3>
      </div>
      <div className="description">{video.description}</div>
      {video.summary && (
        <div className="videoTranscript">
          <a href={video.summary} target={'_blank'}>
            <span>
              <FontAwesomeIcon icon={faScroll}></FontAwesomeIcon>
              &nbsp;Video Transcript
            </span>
          </a>
        </div>
      )}
    </div>
  )
}

Video.propTypes = {
  brand: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  isCategoryMenuOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  video: PropTypes.object.isRequired,
  videoPlayerId: PropTypes.string
}

export default Video
