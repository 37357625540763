import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { CategoryLinks } from 'components/'

class CategoriesMenu extends Component {
  state = {
    isMenuOpen: false
  }

  handleMenuToggle = () => {
    this.setState(
      currentState => ({
        isMenuOpen: !currentState.isMenuOpen
      }),
      () => {
        this.props.onMenuToggle && this.props.onMenuToggle(this.state.isMenuOpen)
      }
    )
  }

  render() {
    const { brand, entity, items, title } = this.props
    const { isMenuOpen } = this.state

    return (
      <div className="categories-menu">
        <h1>
          <button
            onClick={this.handleMenuToggle}
            onKeyPress={this.handleMenuToggle}
            tabIndex="0"
            role="menuitem"
          >
            {title} <i className="material-icons">keyboard_arrow_down</i>
          </button>
        </h1>
        {isMenuOpen && (
          <div className="category-menu">
            <CategoryLinks brand={brand} entity={entity} items={items} />
          </div>
        )}
      </div>
    )
  }
}
CategoriesMenu.propTypes = {
  brand: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onMenuToggle: PropTypes.func,
  title: PropTypes.string.isRequired
}

export default CategoriesMenu
