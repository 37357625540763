import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { PFW_SCORE_INDICATOR } from 'constants/app'

const Results = ({ brand, results }) => {
  const circumference = 753.9816
  const size = 270
  const stroke = 30
  const radius = size / 2 - stroke / 2
  const offset = -(circumference * (1 - results.averageScore / 10))
  const resultIndefinite =
    ['1', '8'].indexOf(results.averageScore.toString().split('.')[0]) < 0 ? 'a' : 'an'
  const roundedScore = Math.round(results.averageScore * 100) / 100
  useEffect(() => {
    const el = document.getElementById("scoreHeader")
    el.focus()
    el.style.outline = "none"
  })
  return (
    <div className="results">
      <div className="chart">
        <svg className="progress" width={size + 20} height={size + 20}>
          <defs>
            <filter id="shadow" filterUnits="userSpaceOnUse" height="120%" width="120%">
              <feDropShadow dx="4" dy="8" stdDeviation="4" floodColor="#ccc" />
            </filter>
          </defs>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke="#e6e6e6"
            strokeWidth={stroke}
            style={{ filter: 'url(#shadow)' }}
          />
          <circle
            className="progress-value"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke={brand.colors.headerTextColor}
            strokeWidth={stroke}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
          />
          <text textAnchor="middle" x="47%" y="40%" fontSize="18">
            YOUR SCORE
          </text>
          <text
            textAnchor="middle"
            x="47%"
            y="60%"
            fontSize="48"
            fontWeight="700"
            fill={brand.colors.headerTextColor}
          >
            {roundedScore}
          </text>
        </svg>
      </div>
      <div className="explanation">
        <h1 style={{ color: brand.colors.headerTextColor }} id="scoreHeader" tabIndex="-1">YOUR FINANCIAL WELL-BEING SCORE</h1>
        <p>
          Your score is {resultIndefinite} {roundedScore}! This indicates&nbsp;
          {PFW_SCORE_INDICATOR[Math.floor(results.averageScore)]}.
        </p>
        <p>
          The average score for Americans is 5.7. 30% percent of people score between 1 and 4, while
          42% score between 7 and 10, and 28% score between 5 and 6.
        </p>
        <p>
          Working with a Money Coach is a proven method to help reduce financial stress and help you
          reach your financial goals. Learn more about your{' '}
          <Link to="/coaching">coaching benefit</Link>.
        </p>
      </div>
    </div>
  )
}

Results.propTypes = {
  brand: PropTypes.object.isRequired,
  results: PropTypes.object.isRequired
}

export default Results
