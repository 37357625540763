import cheerio from 'cheerio'

import {
  ARTICLE_CATEGORIES_UPDATE,
  ARTICLE_CATEGORY_UPDATE,
  ARTICLE_LOAD_FAILURE,
  ARTICLE_LOAD_SUCCESS,
  ARTICLES_LIST_FAILURE,
  ARTICLES_LIST_REQUEST,
  ARTICLES_LIST_SUCCESS,
  ARTICLES_PAGE_UPDATE
} from 'constants/actionTypes'
import { FAILURE, SUCCESS } from 'constants/app'

const initialState = {
  activeCategory: null,
  categories: [],
  current: {
    category: null,
    item: {},
    message: null,
    page: 1,
    status: null
  },
  isFetching: false,
  list: [],
  message: null,
  status: null
}

export default function articles(state = initialState, action) {
  switch (action.type) {
    case ARTICLE_CATEGORIES_UPDATE:
      return {
        ...state,
        categories: action.list
      }
    case ARTICLE_CATEGORY_UPDATE:
      return {
        ...state,
        current: {
          ...state.current,
          category: action.item
        }
      }
    case ARTICLE_LOAD_FAILURE:
      return {
        ...state,
        current: {
          ...state.current,
          message: action.message,
          status: FAILURE
        }
      }
    case ARTICLE_LOAD_SUCCESS:
      const item = action.item
      const $ = cheerio.load(item.description, { xmlMode: true })

      // remove the hero image from the description
      $('img[class*=wp-image]')
        .parent()
        .remove()
      item.description = $.html()

      return {
        ...state,
        current: {
          ...state.current,
          item,
          message: null,
          status: SUCCESS
        }
      }
    case ARTICLES_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        status: FAILURE
      }
    case ARTICLES_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case ARTICLES_LIST_SUCCESS:
      return {
        ...state,
        activeCategory: action.category,
        isFetching: false,
        list: action.list,
        message: null,
        status: SUCCESS
      }
    case ARTICLES_PAGE_UPDATE:
      return {
        ...state,
        current: {
          ...state.current,
          page: action.item
        }
      }
    default:
      return state
  }
}
