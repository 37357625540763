import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

import { FAILURE, PHONE_MASK } from 'constants/app'
import { resetContactStatus, sendContactMessage } from 'actions/contact'

export class Contact extends Component {
  state = {
    firstName: '',
    isValid: false,
    lastName: '',
    email: '',
    phone: '',
    messsage: ''
  }

  componentDidMount() {
    const { resetContactStatus } = this.props
    resetContactStatus()
  }

  handleChange = se => {
    this.setState({ [se.target.name]: se.target.value }, this.validateForm)
  }

  handleFormSubmit = se => {
    const { sendContactMessage } = this.props
    const { firstName, lastName, email, message, phone } = this.state

    se.preventDefault()
    sendContactMessage({ firstName, lastName, email, message, phone })
  }

  validateForm() {
    const { email, message, phone } = this.state
    this.setState({ isValid: (email || phone) && message })
  }

  render() {
    const { contact } = this.props
    return !contact.status || contact.status === FAILURE
      ? this.renderForm(contact)
      : this.renderMessage(contact)
  }

  renderForm(contact) {
    const { brand } = this.props
    const { firstName, isValid, lastName, email, message, phone } = this.state

    return (
      <div className="contact">
        <h2 style={{ color: brand.colors.headerTextColor }}>Contact Us</h2>
        <form onSubmit={this.handleFormSubmit}>
          <div className="fullname">
            <div>
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={this.handleChange}
                style={{ borderColor: brand.colors.headerTextColor }}
              />
            </div>
            <div>
              <label htmlFor="lastName">
                Last Name
                <input
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                  style={{ borderColor: brand.colors.headerTextColor }}
                />
              </label>
            </div>
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <MaskedInput
              mask={emailMask}
              placeholder="name@company.com"
              id="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              style={{ borderColor: brand.colors.headerTextColor }}
            />
          </div>
          <div>
            <label htmlFor="phone">Phone</label>
            <MaskedInput
              mask={PHONE_MASK}
              placeholder="(555) 555-5555"
              id="phone"
              name="phone"
              value={phone}
              onChange={this.handleChange}
              style={{ borderColor: brand.colors.headerTextColor }}
            />
          </div>
          <div>
            <label htmlFor="message">Comments</label>
            <textarea
              id="message"
              name="message"
              placeholder="Type your message here"
              rows="6"
              value={message}
              onChange={this.handleChange}
              style={{ borderColor: brand.colors.headerTextColor }}
            />
          </div>
          <button
            className="button"
            type="submit"
            disabled={contact.isSending || !isValid}
            style={{
              backgroundColor: brand.colors.buttonBackgroundColor,
              color: brand.colors.buttonTextColor
            }}
          >
            Submit
          </button>
        </form>
      </div>
    )
  }

  renderMessage(contact) {
    return (
      <div className="contact">
        <h1>Thank You!</h1>
        <p>{contact.message}</p>
      </div>
    )
  }
}

Contact.propTypes = {
  brand: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  resetContactStatus: PropTypes.func.isRequired,
  sendContactMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  brand: state.brand,
  contact: state.contact
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetContactStatus,
      sendContactMessage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
